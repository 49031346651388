// 底部公共组件

import { Menu, Popover } from 'antd';
import router_menu from '../../common/router_menu';
import { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import url from '../../common/url_config'

const { SubMenu } = Menu

function App() {

  useEffect(() => {

  }, [])

  return (
    <div>
      <div className='index_bottom_black'>
        <div className='main_width btn_box'>
          <img style={{ width: '20%' }} src={url + 'public/web/首页/logo_bottom.png'} />

          <div style={{ width: '30%' }}>
            <div style={{ width: '80%' }} className='btn_box'>
              <div className='index_bottom_icon_box'>
                <Popover content={<h2>邮箱: xuanhuai@tju.edu.cn</h2>}>
                  <img src={url + 'public/web/首页/index_bottom_icon1.png'} />
                  <div>邮箱</div>
                </Popover>
              </div>
              <div className='index_bottom_icon_box'>
                <Popover content={<img style={{ width: 200 }} src={url + 'public/web/浮动二维码视频号.jpg'} />}>
                  <img src={url + 'public/web/首页/index_bottom_icon2.png'} />
                  <div>视频号</div>
                </Popover>
              </div>
              <div className='index_bottom_icon_box'>
                <Popover content={<img style={{ width: 200 }} src={url + 'public/web/浮动二维码.jpg'} />}>
                  <img src={url + 'public/web/首页/index_bottom_icon3.png'} />
                  <div>公众号</div>
                </Popover>
              </div>
            </div>
            <div style={{ fontSize: 20, marginTop: 40 }}>友情链接</div>
            <div style={{ marginTop: 5 }}>
              <a target={'_blank'} href='https://www.tju.edu.cn/'>天津大学官网</a>
              <a target={'_blank'} href='https://alumni.tju.edu.cn/xwzx/xydt.htm' style={{ marginLeft: 10 }}>天津大学校友会</a>
            </div>
            <div style={{ marginTop: 20 }}>邮箱：xuanhuai@tju.edu.cn</div>
            <div>地址：天津市南开区卫津路92号天津大学第25教学楼C区</div>
          </div>

          <div style={{ width: '30%' }}>
            {/* <div style={{ fontSize: 20 }}>常见问题</div>
            <div style={{ marginTop: 10 }}>我希望增设的课程有?</div>
            <div>如何申请入校学习?</div> */}
            <div style={{ marginTop: 30, width: '70%' }} className='btn_box'>
              <div style={{ fontSize: 16 }}>
                <div>使用帮助</div>
                <div style={{ marginTop: 15 }}>隐私声明</div>
              </div>
              <img style={{ width: '60%' }} src={url + 'public/web/浮动二维码.jpg'} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#19171c', padding: '20px 0', color: '#ffffff', marginTop: -5 }}>
        <div className='main_width btn_box'>
          <div>©2022 Finsweet | All rights reserved.</div>
          <img src={url + 'public/web/首页/facebook.png'} />
          <a style={{ color: '#ffffff' }}>Contact Us&nbsp;&nbsp;—— &gt;</a>
        </div>
      </div>
    </div>
  )
}

export default App