// 头部公共组件

import { message, Form, Input, Button, Modal, notification, Popover } from 'antd'
import router_menu from '../../common/router_menu';
import { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import '../../styles/web.css'
import logo_img from '../../img/logo.png'
import { SearchOutlined, WechatOutlined, MailOutlined } from '@ant-design/icons';
import url from '../../common/url_config'

// 菜单导航
const menu_set = [
  {
    key: '首页',
    href: '/#',
    index_of: '',
  },
  {
    key: '双创生态',
    href: '/double_create',
    index_of: '/double_create',
  },
  {
    key: '双创活动',
    href: '/activity',
    index_of: '/activity',
  },
  {
    key: '学生课程',
    href: '/course',
    index_of: '/course',
  },
  {
    key: '教育项目',
    href: '/edu_project/new_class',
    index_of: '/edu_project',
  },
  {
    key: '创业实战',
    href: '/entrepreneurship_practice/x_lab',
    index_of: '/entrepreneurship_practice',
  },
  {
    key: '创业孵化',
    href: '/entrepreneurship_incubation/gold_seed',
    index_of: '/entrepreneurship_incubation',
  },
  {
    key: 'EDP',
    href: '/edp',
    index_of: '/edp',
  },
]

function App() {
  let [current_page, set_current_page] = useState('首页')//当前页面

  const log_out = () => {
    let is_confirm = window.confirm('确认退出登陆？')
    if (!is_confirm) {
      return
    }
    cookie.remove('login_username')
    cookie.remove('session_key')
    window.location.href = '/'
    window.location.reload()
  }

  useEffect(() => {
    let temp_url = window.location.href
    for (let i in menu_set) {
      if (temp_url.indexOf(menu_set[i]['index_of']) > -1) {
        set_current_page(menu_set[i]['key'])
      }
    }

    if (temp_url.indexOf('/news_list/s1_') > -1) {
      set_current_page('双创生态')
    }
    if (temp_url.indexOf('/news_list/s2_') > -1) {
      set_current_page('学生课程')
    }
    if (temp_url.indexOf('/news_list/s3_') > -1) {
      if (temp_url.indexOf('/news_list/s3_5') > -1) {
        set_current_page('双创活动')
      } else if (temp_url.indexOf('/news_list/s3_4_1') > -1 || temp_url.indexOf('/news_list/s3_4_2') > -1) {
        set_current_page('教育项目')
      } else if (temp_url.indexOf('/news_list/s3_4_3') > -1 || temp_url.indexOf('/news_list/s3_4_4') > -1) {
        set_current_page('创业实战')
      } else if (temp_url.indexOf('/news_list/s3_4_5') > -1) {
        set_current_page('创业孵化')
      } else {
        set_current_page('双创生态')
      }
    }
  }, [])

  return (
    <div>
      <div style={{ backgroundColor: '#8d1c22', color: '#ffffff' }}>
        <div style={{ height: 65, }} className='btn_box main_width'>
          <div></div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {cookie.load('login_username') ? <span>
              [{cookie.load('login_role')}] {cookie.load('login_username')}
              {cookie.load('login_role') === '管理员' && <a href='/admin/user' target='_blank' style={{ marginLeft: 5, color: '#ffffff' }}>进入管理后台</a>}
              <span onClick={() => log_out()} style={{ cursor: 'pointer', marginLeft: 5 }}><b>退出登录</b></span>
            </span> : <a style={{ color: '#ffffff' }} href='/login'>登录 / 注册</a>}
            <Input addonAfter={<SearchOutlined style={{ cursor: 'pointer', }} />} style={{ width: 190, marginLeft: 20, opacity: 0.8 }} />
            <Popover content={<img style={{ width: 200 }} src={url + 'public/web/浮动二维码.jpg'} />}>
              <WechatOutlined style={{ marginLeft: 20, cursor: 'pointer', fontSize: 18 }} />
            </Popover>
            <Popover content={<h2>邮箱: xuanhuai@tju.edu.cn</h2>}>
              <MailOutlined style={{ marginLeft: 10, cursor: 'pointer', fontSize: 18 }} />
            </Popover>
          </div>
        </div>
      </div>

      <div style={{ height: 90 }} className='btn_box main_width'>
        <a href='/'><img style={{ width: 235, height: 64 }} src={logo_img} /></a>
        <div>
          {menu_set.map((item: any) => <a
            className={current_page === item.key ? 'nav_menu nav_menu_focus' : 'nav_menu'}
            style={{}}
            onClick={() => {
              window.location.href = (item.href || '/')
            }}
            key={item.key}>{item.title || item.key}</a>)}
        </div>
      </div>
    </div >
  )
}

export default App