// 公共函数

import { saveAs } from 'file-saver'
import jQuery from "jquery"
import cookie from 'react-cookies'
import axios from 'axios'

// post请求发送paras返回json
const post_data = (url: string, paras = {}) => {
  let params = {
    ...paras,
    login_id: cookie.load('login_id') || '',
    session_key: cookie.load('session_key') || '',
  }
  return fetch(url, {
    method: 'post',
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  }).then(res => {
    if (res.status === 200 && res.ok) {
      return res.json()
    } else {
      return ({ status: false, result: res.status })
    }
  }).catch(res => ({ status: false, result: '网络错误：' + res }))
}

// 为数组data添加索引
const add_index_for_fun = (arr: [any]) => {
  arr.map((item, index) => {
    item.index = index
    item.process_status_button = false
  })
  return arr
}

// 格式化路由数据
const format_router = (data: any) => {
  const router: any = []
  const circle = (data: any) => {
    return data.map((item: any) => {
      if (item.children) {
        circle(item.children)
      } else {
        router.push({ route: item.route, component: item.component, para: item.para ? item.para : '' })
      }
    })
  }
  circle(data)
  return router
}

// 得到y-m-d日期
const formatDate = (now: any) => {
  let y
  let m
  let d
  y = now.getFullYear();
  m = now.getMonth() + 1;
  d = now.getDate();
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
}
// 得到h-mi-s时间
const formatDate2 = (now: any) => {
  let h
  let mi
  let s
  h = now.getHours()
  mi = now.getMinutes()
  s = now.getSeconds()
  return (h < 10 ? "0" + h : h) + ':' + (mi < 10 ? "0" + mi : mi) + ':' + (s < 10 ? "0" + s : s);
}

const formateTime = (now: any) => {
  let y
  let m
  let d
  let h
  let mi
  let s
  y = now.getFullYear();
  m = now.getMonth() + 1;
  d = now.getDate();
  h = now.getHours()
  mi = now.getMinutes()
  s = now.getSeconds()
  return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + ' ' + (h < 10 ? "0" + h : h) + ':' + (mi < 10 ? "0" + mi : mi) + ':' + (s < 10 ? "0" + s : s);
}

// 第n年的第n周，获取开始和结束时间 （星期一为一周的开始），例如2020-1
function formate_week_date(weekstr: any) {
  let year = weekstr.split("-")[0]
  let weekNo = Number(weekstr.split("-")[1]) + 1
  // 此年1号是星期几
  let oneday = new Date(year + '-01-01').getDay() //0-6
  // 方便计算，当为星期天时为7
  if (oneday == 0) {
    oneday = 7
  }

  let one_fistday;
  let one_lastday;
  // 如果1号刚好是星期一
  if (oneday == 1) {
    one_fistday = year + '-01-01'
    one_lastday = year + '-01-07'
  } else {
    let jj = 8 - oneday
    one_fistday = (year - 1) + '-12-' + (31 - oneday + 2 > 9 ? 31 - oneday + 2 : '0' + (31 - oneday + 2))
    one_lastday = year + '-01-' + (jj > 9 ? jj : '0' + jj)
  }

  let fistday;
  let lastday;
  // 如果刚好是第一周
  if (weekNo == 1) {
    fistday = one_fistday
    lastday = one_lastday
  } else {
    fistday = addDate(one_lastday, (weekNo - 2) * 7 + 1)
    lastday = addDate(one_lastday, (weekNo - 1) * 7)
  }
  return [fistday, lastday]
}

//日期加减法  date参数为计算开始的日期，days为需要加的天数   
//格式:addDate('2017-1-11',20) 
function addDate(date: any, days: any) {
  var d = new Date(date);
  d.setDate(d.getDate() + days);
  var m = d.getMonth() + 1;
  return d.getFullYear() + '-' + (m > 9 ? m : '0' + m) + '-' + (d.getDate() > 9 ? d.getDate() : '0' + d.getDate());
}

const slice_date = (date: string) => date.slice(0, 10)

// 去除特殊字符串
const delete_string = (str: string) => {
  if (str === null || str === undefined) {
    return ''
  }
  str = str.replace(/\s*/g, "")
  return str.replace(/[\^\-\,\@\!\|\~\`\(\)\#\+\=\$\%\^\&\*\{\}\:\;\"\'\<\>\?]/g, '')
}

// 去除换行回车符
const delete_enter = (str: string) => {
  if (str === null || str === undefined) {
    return ''
  }
  str = str.replace(/\ +/g, "");
  str = str.replace(/[\r\n]/g, "");
  return str
}

// 检测输入
const test_input = {
  tel: (tel: any) => {
    const t = Number(tel)
    if (isNaN(t) || t < 13000000000 || t >= 20000000000) {
      return false
    } else {
      return true
    }
  },
  filter_string: (str: string) => {
    const arr = ["'", '"', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')',]
    let str_new = String(str)
    for (let i = 0; i < str_new.length; i++) {
      if (str_new[i] == ' ') {
        return true
      }
      for (let j = 0; j < arr.length; j++) {
        if (str_new[i] === arr[j]) {
          return true
        }
      }
    }
    return false
  },
  contain_chinese: (str: string) => {
    if (escape(str).indexOf("%u") < 0) { return false } else { return true }
  }
}

const find_value = (data: any, key1: string, value1: string) => {
  for (let i in data) {
    let item = data[i]
    if (item[key1] == value1) {
      return ({ ...item, index: i })
    }
  }
  return {}
}

const isInteger = (obj: number) => {
  return obj % 1 === 0
}

// 对比数组，找出不同。每个数组都只能是字符串数组
const match_arr = (arr_old: [any], arr_new: [any]) => {
  const add_con = []
  const delete_con = []
  for (let i in arr_old) {
    if (arr_new.indexOf(arr_old[i]) == -1) {
      delete_con.push(arr_old[i])
    }
  }
  for (let i in arr_new) {
    if (arr_old.indexOf(arr_new[i]) == -1) {
      add_con.push(arr_new[i])
    }
  }
  return { add_con, delete_con }
}

// html->word
const export_doc = (name = '导出') => {
  if (typeof jQuery !== "undefined" && typeof saveAs !== "undefined") {
    (function ($: any) {
      $.fn.wordExport = function (fileName: string) {
        fileName = typeof fileName !== 'undefined' ? fileName : "jQuery-Word-Export";
        var statics = {
          mhtml: {
            top: "Mime-Version: 1.0\nContent-Base: " + window.location.href +
              "\nContent-Type: Multipart/related; boundary=\"NEXT.ITEM-BOUNDARY\";type=\"text/html\"\n\n--NEXT.ITEM-BOUNDARY\nContent-Type: text/html; charset=\"utf-8\"\nContent-Location: " +
              window.location.href + "\n\n<!DOCTYPE html>\n" +
              "<html xmlns:v=\"urn:schemas-microsoft-com:vml\" xmlns:o=\"urn:schemas-microsoft-com:office:office\" xmlns:w=\"urn:schemas-microsoft-com:office:word\" xmlns:m=\"http://schemas.microsoft.com/office/2004/12/omml\" xmlns=\"http://www.w3.org/TR/REC-html40\">\n_html_</html>",
            head: "<head>\n<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\">\n<style>\n_styles_\n</style>\n<!--[if gte mso 9]><xml><w:WordDocument><w:View>Print</w:View><w:TrackMoves>false</w:TrackMoves><w:TrackFormatting/><w:ValidateAgainstSchemas/><w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid><w:IgnoreMixedContent>false</w:IgnoreMixedContent><w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText><w:DoNotPromoteQF/><w:LidThemeOther>EN-US</w:LidThemeOther><w:LidThemeAsian>ZH-CN</w:LidThemeAsian><w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript><w:Compatibility><w:BreakWrappedTables/><w:SnapToGridInCell/><w:WrapTextWithPunct/><w:UseAsianBreakRules/><w:DontGrowAutofit/><w:SplitPgBreakAndParaMark/><w:DontVertAlignCellWithSp/><w:DontBreakConstrainedForcedTables/><w:DontVertAlignInTxbx/><w:Word11KerningPairs/><w:CachedColBalance/><w:UseFELayout/></w:Compatibility><w:BrowserLevel>MicrosoftInternetExplorer4</w:BrowserLevel><m:mathPr><m:mathFont m:val=\"Cambria Math\"/><m:brkBin m:val=\"before\"/><m:brkBinSub m:val=\"--\"/><m:smallFrac m:val=\"off\"/><m:dispDef/><m:lMargin m:val=\"0\"/> <m:rMargin m:val=\"0\"/><m:defJc m:val=\"centerGroup\"/><m:wrapIndent m:val=\"1440\"/><m:intLim m:val=\"subSup\"/><m:naryLim m:val=\"undOvr\"/></m:mathPr></w:WordDocument></xml><![endif]--></head>\n",
            body: "<body>_body_</body>"
          }
        };
        var options = {
          maxWidth: 624
        };
        // Clone selected element before manipulating it
        var markup = $(this).clone();

        // Remove hidden elements from the output
        markup.each(() => {
          var self = $(this);
          if (self.is(':hidden'))
            self.remove();
        });

        // Embed all images using Data URLs
        var images = Array();
        var img: any = new Image();
        for (var i = 0; i < img.length; i++) {
          // Calculate dimensions of output image
          var w = Math.min(img[i].width, options.maxWidth);
          var h = img[i].height * (w / img[i].width);
          // Create canvas for converting image to data URL
          var canvas: any = document.createElement("CANVAS");
          canvas.width = w;
          canvas.height = h;
          // Draw image to canvas
          var context = canvas.getContext('2d');
          context.drawImage(img[i], 0, 0, w, h);
          // Get data URL encoding of image
          var uri = canvas.toDataURL("image/png");
          $(img[i]).attr("src", img[i].src);
          img[i].width = w;
          img[i].height = h;
          // Save encoded image to array
          images[i] = {
            type: uri.substring(uri.indexOf(":") + 1, uri.indexOf(";")),
            encoding: uri.substring(uri.indexOf(";") + 1, uri.indexOf(",")),
            location: $(img[i]).attr("src"),
            data: uri.substring(uri.indexOf(",") + 1)
          };
        }

        // Prepare bottom of mhtml file with image data
        var mhtmlBottom = "\n";
        for (var i = 0; i < images.length; i++) {
          mhtmlBottom += "--NEXT.ITEM-BOUNDARY\n";
          mhtmlBottom += "Content-Location: " + images[i].location + "\n";
          mhtmlBottom += "Content-Type: " + images[i].type + "\n";
          mhtmlBottom += "Content-Transfer-Encoding: " + images[i].encoding + "\n\n";
          mhtmlBottom += images[i].data + "\n\n";
        }
        mhtmlBottom += "--NEXT.ITEM-BOUNDARY--";

        //TODO: load css from included stylesheet
        var styles = "";

        // Aggregate parts of the file together
        var fileContent = statics.mhtml.top.replace("_html_", statics.mhtml.head.replace("_styles_", styles) +
          statics.mhtml.body.replace("_body_", markup.html())) + mhtmlBottom;

        // Create a Blob with the file contents
        var blob = new Blob([fileContent], {
          type: "application/msword;charset=utf-8"
        });
        saveAs(blob, fileName + ".doc");
      };
      $("#main_content").wordExport(name)
    })(jQuery);
  } else {
    if (typeof jQuery === "undefined") {
      console.error("jQuery Word Export: missing dependency (jQuery)");
    }
    if (typeof saveAs === "undefined") {
      console.error("jQuery Word Export: missing dependency (FileSaver.js)");
    }
  }
}

// 上传大文件
const uploadFile = (obj: any, callback: any) => {
  axios.defaults.withCredentials = true
  let { file, urlUpload, urlMerge, slicingBig = 1024 } = obj
  if (!file) return
  var arrAxios = []
  var slicingSize = 1024 * 1024 * slicingBig;//-----------------------------------------这里可以设置多大内存分成一片现在是1g
  var slicingCount: any = Math.ceil(file.size / slicingSize); // 分片总数
  var ext = file.name.split('.');
  ext = ext[ext.length - 1]; // 获取文件后缀名
  var random = Math.floor(Math.random() * (100 - 1)) + 1;
  var hash = Date.now() + random + file.lastModified; // 文件 hash 实际应用时，hash需要更加复杂，确保唯一性，可以使用uuid
  //创建数组代理来判断完成多久
  var arr = new Proxy(new Array(slicingCount), {
    set(target, key, value, receiver) {
      var prose = 0;
      target.forEach(item => {
        if (item) {
          prose += item
        }
      })
      callback({ name: 'upload', value: (prose / slicingCount).toFixed(2), msg: "上传中" })
      return Reflect.set(target, key, value, receiver);
    },
    get(target, key: any) {
      return target[key];
    }
  })
  for (let i = 0; i < slicingCount; i++) {
    let start = i * slicingSize,
      end = Math.min(file.size, start + slicingSize);
    const form = new FormData();//通过formdata的方式提交
    form.append('file', file.slice(start, end));
    form.append('name', file.name);
    form.append('total', slicingCount);
    form.append('ext', ext);
    form.append('index', String(i));
    form.append('size', file.size);
    form.append('hash', hash);
    form.append('login_role', cookie.load('login_role') || '');
    form.append('login_username', cookie.load('login_username') || '');
    form.append('session_key', cookie.load('session_key') || '');
    arrAxios.push(axios.post(urlUpload, form, {
      onUploadProgress: (progressEvent: any) => {
        let complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
        arr[i] = complete;
      }
    }));//这改成你的分片请求路径
  }
  axios.all(arrAxios).then((res: any) => {
    const data = {
      name: file.name,
      fileId: "2",
      total: slicingCount,
      ext,
      hash,
      login_role: cookie.load('login_role') || '',
      login_username: cookie.load('login_username') || '',
      session_key: cookie.load('session_key') || '',
    };
    axios.post(urlMerge, data, {
      onUploadProgress: (progressEvent: any) => {
        let complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
        callback({ name: 'merge', value: complete, msg: "合并中" })
      }
    }).then((res: any) => {//这改成你的分片合并请求路径
      callback({ name: 'ok', value: res.data, msg: "完成" })
    }).catch((err: any) => {
      callback({ name: 'err', value: err, msg: "失败" })
    })
  })
}

// 判断是否闰年
const is_leap_year = (year: number) => {
  if (year % 4 == 0 && year % 100 != 0 || year % 400 == 0) {
    return true
  } else {
    return false
  }
}
// 计算+1-1天
const calculate_day = (day: any, num: number) => {
  return new Date(new Date(day).getTime() + 3600000 * 24 * num)
}
// 获取某年某月的周日历
const get_calendar_date = (month_current: string) => {
  let month = month_current + '-01'
  let week = new Date(month).getDay()
  let start_date = calculate_day(month, -1 * week + 1)
  // console.log('start_date', month, week, start_date)
  let row = 5
  let current_month = new Date(month).getMonth()
  let last_after_day_month = calculate_day(start_date, row * 7).getMonth()
  if (current_month === last_after_day_month) {
    row = 6
  }
  let date_set = []
  for (let i = 0; i < row * 7; i++) {
    let item = calculate_day(start_date, i)
    let date_string = formatDate(item).slice(0, 10)
    date_set.push({
      date: item,
      date_string,
      week: item.getDay(),
      current_month: formatDate(new Date(month)).slice(0, 7) == date_string.slice(0, 7)
    })
  }
  return date_set
}
// 将每天变成每周
const formate_week_day = (temp: any) => {
  let week_day_set: any = []
  for (let i = 0; i < temp.length / 7; i++) {
    week_day_set[i] = []
    for (let j = 0; j < 7; j++) {
      week_day_set[i].push(temp[7 * i + j])
    }
  }
  // console.log('week_day_set', week_day_set)
  return week_day_set
}
// 将年份转换为星期（周一至周日为一个周期）
const formate_year_to_week = (days_set: any, start_week: any) => {
  if (start_week === 0) {
    let temp = [
      [null, null, null, null, null, null, days_set[0]],
      ...formate_week_day(days_set.slice(1, days_set.length))
    ]
    return temp
  } else {
    let temp_first = days_set.slice(0, 8 - start_week)
    let temp_set = []
    for (let i = 0; i < (7 - temp_first.length); i++) {
      temp_set.push(null)
    }
    let temp = [
      [...temp_set, ...temp_first],
      ...formate_week_day(days_set.slice(8 - start_week, days_set.length))
    ]
    return temp
  }
}
// 将年份获取所有周
const get_weeks_from_year = (current_year: string) => {
  let current_week_index = 0
  let start_day = current_year + '-01-01'
  let start_week = new Date(start_day).getDay() //一年首日星期
  let count_day = is_leap_year(Number(current_year)) ? 366 : 365 //总天数
  let days_set = []
  for (let i = 0; i < count_day; i++) {
    days_set.push(formate_to_week(calculate_day(start_day, i)))
  }

  function formate_to_week(date: any) {
    return ({
      date,
      date_string: formateTime(date).slice(0, 10),
      week: date.getDay(),
    })
  }
  let year_week_set = formate_year_to_week(days_set, start_week)
  // 计算当前是第几周
  let current_date = formateTime(new Date()).slice(0, 10)
  for (let i in year_week_set) {
    for (let j in year_week_set[i]) {
      if (year_week_set[i][j] && year_week_set[i][j]['date_string'] === current_date) {
        current_week_index = Number(i)
        break
      }
    }
  }
  return ({ current_week_index, year_week_set })
}

// 格式化7-23点
const formate_hours_set = (start_hour: number = 7, end_hour: number = 23) => {
  let hours_set = []
  for (let i = start_hour; i < end_hour; i++) {
    hours_set.push({
      h: i,
      start_hour: (i < 10 ? ('0' + i) : i) + ':00',
      end_hour: ((i + 1) < 10 ? ('0' + (i + 1)) : (i + 1)) + ':00'
    })
  }
  return hours_set
}

function hasSpecialCharacters(str: string) {
  // 匹配任何非字母、非数字、非空格字符的正则表达式
  var regex = /[^a-zA-Z0-9\s]/;
  return regex.test(str);
}

function containsLetter(str: string) {
  return /[A-Za-z]/.test(str);
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  containsLetter,
  hasSpecialCharacters,
  post_data,
  add_index_for_fun,
  format_router,
  formatDate,
  formatDate2,
  test_input,
  delete_string,
  slice_date,
  formateTime,
  find_value,
  isInteger,
  match_arr,
  delete_enter,
  export_doc,
  formate_week_date,
  uploadFile,
  calculate_day,
  get_calendar_date,
  formate_week_day,
  formate_year_to_week,
  get_weeks_from_year,
  formate_hours_set,
}