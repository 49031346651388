// 轮播组件

import { useState, useEffect } from 'react'
import { Carousel, Tag, message, Form, Input, Button, Modal, notification, DatePicker, Spin, ConfigProvider, Popover, } from 'antd'
import fun from '../../common/common_fun'
import url from '../../common/url_config'
import { UserOutlined, LockOutlined, DownOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import cookie from 'react-cookies'
import HeaderTop from '../../components/menu/header'
import FooterBottom from '../../components/menu/footer'
import ContactRight from '../../components/contact_right'
import common_data from './../../common/common_data'
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';

function App(props: any) {
  let [data, set_data] = useState([])

  useEffect(() => {
    fun['post_data'](url + 'get_banner', { '类型': props.page }).then(res => {
      if (res.status) {
        set_data(res.result.rows)
      } else {
        message.error('failed:' + JSON.stringify(res.result))
      }
    })
  }, [])

  if (!props.page) {
    return <></>
  }

  return (
    <Carousel autoplay={true} infinite={true}>
      {data.map((item: any) => <img key={item.id} src={url + item['图片URL']} />)}
    </Carousel>
  )
}

export default App